import EmberRouter from '@ember/routing/router';
import additiveAuthRouter from '@additive-apps/auth/router';
import config from './config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('auth', function () {
    additiveAuthRouter(this);
  });

  this.route(
    'instance',
    {
      path: ':instance_id'
    },
    function () {
      this.route('dashboard', { path: '/' }, function () {
        this.mount('@additive-apps/multimedia-engine', {
          as: 'additive-multimedia-engine',
          path: 'mm'
        });
      });
      this.route('vouchers', function () {
        this.route('index', { path: '/' }, function () {
          this.route('voucher', { path: ':voucher_id' }, function () {
            this.route('edit', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('create', function () {
            this.mount('@additive-apps/multimedia-engine', {
              as: 'additive-multimedia-engine',
              path: 'mm'
            });
          });
        });
        this.route('categories', function () {
          this.route('category', { path: ':category_id' }, function () {
            this.mount('@additive-apps/multimedia-engine', {
              as: 'additive-multimedia-engine',
              path: 'mm'
            });
          });
          this.route('create', function () {
            this.mount('@additive-apps/multimedia-engine', {
              as: 'additive-multimedia-engine',
              path: 'mm'
            });
          });
        });

        this.route('voucher', function () {
          this.route('index', function () {});
        });
      });

      this.route('incentive-vouchers', function () {
        this.route('index', { path: '/' }, function () {
          this.route('voucher', { path: ':voucher_id' }, function () {
            this.route('edit', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('create', function () {
            this.mount('@additive-apps/multimedia-engine', {
              as: 'additive-multimedia-engine',
              path: 'mm'
            });
          });
        });
        this.route('categories', function () {
          this.route('category', { path: ':category_id' }, function () {
            this.mount('@additive-apps/multimedia-engine', {
              as: 'additive-multimedia-engine',
              path: 'mm'
            });
          });
          this.route('create', function () {
            this.mount('@additive-apps/multimedia-engine', {
              as: 'additive-multimedia-engine',
              path: 'mm'
            });
          });
        });

        this.route('voucher', function () {
          this.route('index', function () {});
        });
      });
      this.route('orders', function () {
        this.route('order', { path: ':order_id' });
      });
      this.route('auditlog');
      this.route('settings', function () {
        this.route('legal');
        this.route('payment-types');
        this.route('texts');
        this.route('shipping-package', function () {
          this.route('shipping', { path: 'shipping/:shipping_id' });
          this.route('package', { path: 'package/:package_id' });
        });
      });
      this.route('widgets', function () {
        this.route('index', { path: '/' }, function () {
          this.route('widget', { path: ':widget_id' }, function () {});
        });

        this.route('templates', function () {
          this.route('template', { path: ':template_id' }, function () {
            this.route('edit');
          });
        });

        this.route('widget', function () {
          this.route('edit');
        });
      });
    }
  );

  this.route('page-not-found', { path: 'not-found' });
  this.route('page-not-found', { path: '*wildcard' });
});
