import Controller from '@ember/controller';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/string';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { task, timeout, all } from 'ember-concurrency';

export default class InstanceIncentiveVouchersIndexController extends Controller {
  @service authenticatedFetch;
  @service intl;
  @service uiFilter;
  @service uiPaths;
  @service uiToast;
  @service uiDialog;
  @service router;
  @service currentUser;

  @tracked
  queryParams = ['category'];

  @tracked
  category = null;

  get isViewer() {
    return this.currentUser?.isViewer;
  }

  /**
   * Returns the last segment of the current URL.
   * @computed _currentRouteIndex
   * @return {String} The last segment of the current URL.
   */
  get _currentRouteIndex() {
    return this.router.currentURL.split('/').pop();
  }

  /**
   * Deletes a single voucher entity
   *
   * @param {Object} voucher
   * @type {Task}
   * @function deleteVoucherTask
   * @private
   */
  @(task(function* (voucher) {
    try {
      let tasks = [];

      // delete voucher and keep the task running for at least 250ms
      tasks.push(voucher.destroyRecord());
      tasks.push(timeout(250));

      yield all(tasks);
      yield this.uiFilter.reset('incentive-vouchers');

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('toast.success'),
        duration: 2000
      });
    } catch (error) {
      if (error?.errors?.length > 0) {
        const errorData = error.errors[0];

        // handle conflict error
        if (errorData.status === 409) {
          const { intl } = this;
          this.uiDialog.showConflictError(
            intl.t('vouchers.voucher.deleteVoucher.conflictError.title'),
            intl.t('vouchers.voucher.deleteVoucher.conflictError.description'),
            errorData.dependencies
          );

          return;
        }
      }

      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('toast.unexpectedError'),
        duration: 2000
      });
    }
  }).drop())
  deleteVoucherTask;

  /**
   * Bulk deletes voucher entities, for a given ids-array, in case the delete operation
   * was successful the list gets reloaded.
   *
   * @param {Array} ids
   * @type {Task}
   * @function bulkDeleteVouchersTask
   * @private
   */
  @(task(function* (ids = []) {
    try {
      let tasks = [];
      if (ids.length === 0) {
        throw new Error('[VOUCHER BULK] Ids param empty');
      }
      const url = this.uiPaths.pathsByRouteName('instance.incentive-vouchers').api().url;
      // save voucher and keep the task running for at least 250ms
      tasks.push(
        this.authenticatedFetch.fetch(
          `${url}/bulk`,
          {
            method: 'DELETE',
            body: JSON.stringify({ ids })
          },
          null,
          [409]
        )
      );
      tasks.push(timeout(250));
      const [response] = yield all(tasks);

      if (response.status > 400) {
        if (response.status === 409) {
          const error = yield response.json();

          // handle conflict error
          const { intl } = this;
          this.uiDialog.showConflictError(
            intl.t('vouchers.voucher.bulkDeleteVouchers.conflictError.title'),
            intl.t('vouchers.voucher.bulkDeleteVouchers.conflictError.description'),
            error.dependencies
          );

          return;
        }

        throw new Error(`[VOUCHER BULK] general ${response}`);
      }

      // reset all filters and reload list
      yield this.uiFilter.reset('incentive-vouchers');

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('toast.bulkDelete', { count: ids.length }),
        duration: 2000
      });
    } catch (error) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('toast.unexpectedError'),
        duration: 2000
      });
    }
  }).drop())
  bulkDeleteVouchersTask;

  @action
  deleteVoucher(voucher) {
    const { intl } = this;
    this.uiDialog.showConfirm(
      intl.t('vouchers.voucher.deleteVoucher.title'),
      htmlSafe(
        intl.t('vouchers.voucher.deleteVoucher.description', {
          name: voucher?.name
        })
      ),
      () => {
        this.deleteVoucherTask.perform(voucher);
      },
      intl.t('global.actions.delete'),
      true,
      true
    );
  }

  @action
  bulkDeleteVouchers(ids = []) {
    const { intl } = this;
    this.uiDialog.showConfirm(
      intl.t('vouchers.voucher.bulkDeleteVouchers.title'),
      htmlSafe(intl.t('vouchers.voucher.bulkDeleteVouchers.description')),
      () => {
        this.bulkDeleteVouchersTask.perform(ids);
      },
      intl.t('vouchers.voucher.bulkDeleteVouchers.action'),
      true,
      true
    );
  }

  @action
  transitionToVoucherDetail(id) {
    // Reset category query param before routing to detail
    this.category = null;
    this.router.transitionTo('instance.incentive-vouchers.index.voucher', id);
  }
}
