// mirage/serializers/voucher.js
import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  embed: false, // Ensure relationships are not embedded

  keyForModel() {
    return 'voucher';
  },

  keyForForeignKey(relationshipName) {
    if (relationshipName === 'category') {
      return 'categoryId';
    }

    // super call
    return ApplicationSerializer.prototype.keyForForeignKey.apply(this, arguments);
  }
});
