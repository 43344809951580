import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.formattedNewsletters.length}}\n  <div class=\"flex flex-column {{@class}}\">\n    <div class=\"font-lg primary mb3\">\n      {{t \"components.avIncentiveVouchers.newsletterList.title\"}}\n    </div>\n\n    <UiList @items={{this.formattedNewsletters}} as |list item|>\n      <list.item\n        @title={{item.title}}\n        @description={{item.description}}\n        @isDisabled={{true}}\n        as |controls|\n      >\n        <controls.right>\n          <UiIconButton @href={{item.link}} @target=\"_blank\" @icon=\"external-link\" />\n        </controls.right>\n      </list.item>\n    </UiList>\n  </div>\n{{/if}}", {"contents":"{{#if this.formattedNewsletters.length}}\n  <div class=\"flex flex-column {{@class}}\">\n    <div class=\"font-lg primary mb3\">\n      {{t \"components.avIncentiveVouchers.newsletterList.title\"}}\n    </div>\n\n    <UiList @items={{this.formattedNewsletters}} as |list item|>\n      <list.item\n        @title={{item.title}}\n        @description={{item.description}}\n        @isDisabled={{true}}\n        as |controls|\n      >\n        <controls.right>\n          <UiIconButton @href={{item.link}} @target=\"_blank\" @icon=\"external-link\" />\n        </controls.right>\n      </list.item>\n    </UiList>\n  </div>\n{{/if}}","moduleName":"additive-voucher/components/av-incentive-vouchers/newsletter-list.hbs","parseOptions":{"srcName":"additive-voucher/components/av-incentive-vouchers/newsletter-list.hbs"}});
import ENV from 'additive-voucher/config/environment';

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import { arg } from 'ember-arg-types';
import { array } from 'prop-types';
import format from 'date-fns/format';

export default class AvIncentiveVouchersNewsletterList extends Component {
  @service currentUser;
  @service intl;
  @service uiLocale;

  @arg(array)
  @tracked
  newsletters = [];

  get newsletterBaseUrl() {
    return `${ENV.APP.newsletterHost}/${this.currentUser.currentOrganization.id}/campaigns/`;
  }

  get formattedNewsletters() {
    return this.newsletters.map((newsletter) => {
      const descriptionParts = [];
      let link = `${this.newsletterBaseUrl}/${newsletter.id}`;

      if (newsletter.addressBookName) {
        descriptionParts.push(
          this.intl.t('components.avIncentiveVouchers.newsletterList.addressBook', {
            name: newsletter.addressBookName
          })
        );
      }

      if (newsletter.sentAt) {
        descriptionParts.push(
          this.intl.t('components.avIncentiveVouchers.newsletterList.sentAt', {
            date: format(new Date(newsletter.sentAt), 'dd.MM.yyyy', {
              locale: this.uiLocale.dateFnsLocale
            })
          })
        );
        link = `${this.newsletterBaseUrl}/sent/${newsletter.id}`;
      } else if (newsletter.sendAt) {
        descriptionParts.push(
          this.intl.t('components.avIncentiveVouchers.newsletterList.sendAt', {
            date: format(new Date(newsletter.sendAt), 'dd.MM.yyyy', {
              locale: this.uiLocale.dateFnsLocale
            })
          })
        );
      }

      return {
        id: newsletter.id,
        title: newsletter.title,
        description: descriptionParts.join(' – '),
        link
      };
    });
  }
}
