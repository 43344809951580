import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { categoryObject } from 'additive-voucher/validations/category';

export default class InstanceVouchersCategoriesCreate extends Route {
  @service uiAppSettings;
  @service store;

  get defaultLanguage() {
    return this.uiAppSettings?.languages?.defaultLanguage;
  }

  model() {
    super.model(...arguments);

    // initialiaze category model with default org language
    const categoryModel = categoryObject(this.defaultLanguage);
    return this.store.createRecord('incentive-category', categoryModel);
  }
}
