import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

import { categoryValidation } from 'additive-voucher/validations/incentive-category';

export default class InstanceIncentiveVouchersCategoriesCreate extends Controller {
  @service intl;
  @service multimedia;
  @service router;
  @service store;
  @service uiFilter;

  validation = null;

  constructor() {
    super(...arguments);

    this.validation = categoryValidation(this.intl);
  }

  @task(function* (record) {
    const categoryModel = yield record.save();
    this.uiFilter.addItem('incentive-categories', categoryModel);
    this.router.transitionTo('instance.incentive-vouchers.categories.category', categoryModel.id);
  })
  onSave;

  onClose() {
    this.router.transitionTo('instance.incentive-vouchers.categories');
  }
}
