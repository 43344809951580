export const IncentiveVouchers = (server) => {
  server.post('/incentive-vouchers', (schema, request) => {
    let body = JSON.parse(request.requestBody);

    return schema.incentiveVouchers.create({
      ...body
    });
  });

  server.get('/incentive-vouchers', (schema) => {
    return schema.incentiveVouchers.all();
  });

  server.get('/incentive-vouchers/:id', (schema, { params: { id } }) => {
    return schema.incentiveVouchers.find(id);
  });

  server.get('/incentive-vouchers/:id/publish', (schema, { params: { id } }) => {
    return schema.incentiveVouchers.find(id);
  });

  server.put('/incentive-vouchers/:id', (schema, request) => {
    const { id } = request.params;
    const requestBody = JSON.parse(request.requestBody);
    const incentiveVoucher = schema.incentiveVouchers.find(id);

    return incentiveVoucher.update(requestBody);
  });

  server.put('/incentive-vouchers/:id/publish', (schema, request) => {
    const { id } = request.params;
    const requestBody = JSON.parse(request.requestBody);
    const incentiveVoucher = schema.incentiveVouchers.find(id);

    return incentiveVoucher.update({ publishedLanguages: requestBody.languages });
  });

  server.delete('/incentive-vouchers/bulk', (schema, request) => {
    const requestBody = JSON.parse(request.requestBody);
    const ids = requestBody.ids;

    ids.forEach((id) => {
      schema.incentiveVouchers.find(id).destroy();
    });
  });

  server.delete('/incentive-vouchers/:id', (schema, { params: { id } }) => {
    const incentiveVoucher = schema.incentiveVouchers.find(id);
    return incentiveVoucher.destroy();
  });
};
