import ApplicationSerializer from './application';

export default class IncentiveCategorySerializer extends ApplicationSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload.categories) {
      payload.incentiveCategories = [...payload.categories];
      delete payload.categories;
    }

    if (payload.category) {
      payload.incentiveCategory = payload.category;
      delete payload.category;
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
