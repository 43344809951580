import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class InstanceIncentiveVouchersController extends Controller {
  @service router;
  @service store;
  @service uiAppSettings;
  @service uiFilter;
  @service currentUser;

  get isViewer() {
    return this.currentUser?.isViewer;
  }

  /**
   * If the search-/filterbar is open the intro should be hidden
   * @computed isIntroVisible
   * @return {Boolean} whether the intro is visiblke or not
   */
  get isIntroVisible() {
    return !this._vouchersFilter?.isOpen;
  }

  /**
   * Returns the name of the used filter on a per route base,
   * in our case only for the vouchers list and we disable search and filter
   * completely for the categories.
   *
   * @computed filter
   * @return {String|Null} the global filter group name
   */
  get filter() {
    return this.isVouchersRoute ? 'incentive-vouchers' : null;
  }

  /**
   * returns true if the active route is the incentive vouchers list route
   * @computed isVouchersRoute
   * @return {Boolean} whether the incentive vouchers route is active or not
   */
  get isVouchersRoute() {
    return this.router?.isActive('instance.incentive-vouchers.index');
  }

  /**
   * Reference to the underlying QueryParams-Instance.
   * @computed _filterObject
   * @return {QueryParameters}
   * @private
   */
  get _vouchersFilter() {
    const dataFilter = this.uiFilter?.getQPFilter('incentive-vouchers');
    return dataFilter?.filter;
  }

  @action
  async loadCategories() {
    const categories = await this.store.findAll('incentive-category');

    return categories.map((category) => {
      return { value: category.id, name: category.name };
    });
  }
}
