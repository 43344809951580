import Route from '@ember/routing/route';

import FullWidthMixin from '@additive-apps/ui/mixins/ui-full-width-view';
import { inject as service } from '@ember/service';

import { expirationFormats } from 'additive-voucher/utils/constants';
import { validation } from 'additive-voucher/validations/incentive-voucher';

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import {
  showWarningDialogBeforeClose,
  removeWarningDialogBeforeClose
} from '@additive-apps/ui/utils/close-tab-utils';

export default class InstanceVouchersIndexVoucherEditRoute extends Route.extend(FullWidthMixin) {
  @service() multimedia;
  @service() intl;
  @service() uiAppSettings;
  @service() router;

  get currentLocale() {
    return this.uiAppSettings?.currentLocale;
  }

  get defaultLanguage() {
    return this.uiAppSettings?.languages?.defaultLanguage;
  }

  renderTemplate() {
    // TODO: Check why renderint into application skips the applications beforeModel-hook
    this.render('instance/incentive-vouchers/index/voucher/edit', {
      into: 'application'
    });
  }

  model() {
    const model = super.model(...arguments);

    if (model.language === this.currentLocale) {
      return model;
    }

    // reset multilingual properties
    model.name = null;
    model.greeting = null;
    model.termsOfUse = null;
    model.language = this.currentLocale;

    return model;
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const intl = this.intl;
    const EXPIRATION_FORMATS = expirationFormats(intl);

    controller.expirations = EXPIRATION_FORMATS;
    controller.selectedExpiration = model.validityPeriod;

    const validator = validation(intl);
    controller.changeset = new Changeset(model, lookupValidator(validator), validator);

    showWarningDialogBeforeClose(
      this.router,
      this.router.currentRouteName?.slice(0, -6),
      () => controller.changeset.get('isDirty'),
      () => controller && (controller.isDiscardChangesDialog = true),
      true
    );
  }

  resetController(controller, isExiting, transition) {
    super.resetController(...arguments);

    removeWarningDialogBeforeClose(this.router);

    if (isExiting && transition.targetName !== 'error') {
      this.uiAppSettings.setLocale(this.defaultLanguage);
      controller.model.rollbackAttributes();
    }

    this.multimedia.resetSelectionOptions();
  }
}
