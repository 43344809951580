import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class InstanceIncentiveVouchersIndexVoucherRoute extends Route {
  @service store;
  @service uiAppSettings;

  renderTemplate() {
    this.render('instance/incentive-vouchers/index/voucher', {
      into: 'instance/incentive-vouchers',
      outlet: 'detail'
    });
  }

  model(params) {
    const storeModel = this.store.peekRecord('incentive-voucher', params.voucher_id);
    if (storeModel) {
      this.uiAppSettings.setLocale(storeModel.language);
      return storeModel;
    }

    return this.store.findRecord('incentive-voucher', params.voucher_id);
  }

  resetController(controller, isExiting) {
    if (
      isExiting &&
      this.uiAppSettings?.languages?.defaultLanguage !== controller.model?.language
    ) {
      this.uiAppSettings.setLocale(this.uiAppSettings.languages.defaultLanguage);

      return (
        !controller.model?.isDestroyed && !controller.model?.isDeleted && controller.model.reload()
      );
    }
  }
}
