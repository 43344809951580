import RESTSerializer from '@ember-data/serializer/rest';

import { centsToAmount, amountToCents } from 'additive-voucher/utils/currency-serialization';

export default class VoucherSerializer extends RESTSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload.vouchers) {
      payload.incentiveVouchers = [...payload.vouchers];
      delete payload.vouchers;
    }

    if (payload.voucher) {
      payload.incentiveVoucher = payload.voucher;
      delete payload.voucher;
    }

    const vouchers = payload.incentiveVouchers
      ? payload.incentiveVouchers
      : [payload.incentiveVoucher];

    vouchers.forEach((voucher) => {
      if (typeof voucher.amount === 'string') {
        voucher.amount = parseFloat(voucher.amount);
      }

      if (voucher.amount) {
        voucher.amount = centsToAmount(voucher.amount);
      }
    });

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }

  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    if (json.amount) {
      json.amount = amountToCents(json.amount);
    }

    return json;
  }

  serializeIntoHash(data, type, record, options) {
    Object.assign(data, this.serialize(record, options));
  }

  keyForRelationship(key, relationship) {
    if (relationship === 'belongsTo') {
      return `${key}Id`;
    }
    return key;
  }
}
