import ApplicationAdapter from './application';
import ConflictError from '@ember-data/adapter/error';

export default class IncentiveVoucherAdapter extends ApplicationAdapter {
  handleResponse(status, _, payload) {
    if (status === 409) {
      return new ConflictError([
        {
          status,
          dependencies: payload.dependencies
        }
      ]);
    }

    return super.handleResponse(...arguments);
  }
}
