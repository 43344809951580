import Route from '@ember/routing/route';

export default class InstanceIncentiveVoucherIndexRoute extends Route {
  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      // reset qps when exiting
      controller.category = null;
    }
  }
}
