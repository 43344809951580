import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class InstanceIncentiveVoucherRoute extends Route {
  @service currentUser;
  @service intl;
  @service router;
  @service uiFilter;

  beforeModel() {
    super.beforeModel(...arguments);

    if (!this.currentUser?.currentOrganization?.hasIncentiveVouchers) {
      this.router.transitionTo('instance');
    }
  }

  setupController() {
    super.setupController(...arguments);

    this.uiFilter.register('incentive-vouchers', 'incentive-voucher', {
      queryParams: {
        category: {
          defaultValue: ''
        }
      }
    });

    this.uiFilter.register('incentive-categories', 'incentive-category', {
      limit: 40,
      filterable: false,
      searchable: false,
      paginated: false
    });
  }

  resetController() {
    super.resetController(...arguments);

    this.uiFilter.unregister('incentive-vouchers');
    this.uiFilter.unregister('incentive-categories');
  }
}
