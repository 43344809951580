import { Factory } from 'ember-cli-mirage';
import faker from 'faker';

export default Factory.extend({
  name: () => faker.lorem.word(),
  language: 'de',
  greeting: () => faker.lorem.word(6),
  termsOfUse: () => faker.lorem.paragraph(),
  image: {
    id: 'deca5743-34e0-4dbe-9451-9eae8c981294',
    mediumId: 'e10f8538-6fb4-4c39-90ab-3c574a073933',
    name: 'Kodak Portra 400',
    position: 0,
    url: 'https://staging.voucher.additive-apps.tech/testhotel-post-com/media/deca5743-34e0-4dbe-9451-9eae8c981294?ts=1734096416&af=1',
    type: 'image',
    mimeType: 'image/jpeg'
  },
  amount: () => faker.random.number(50) * 100,
  availableLanguages: ['de'],
  publishedState: 'error',

  validity_period: 'one_year',

  afterCreate: (incentiveVoucher) => {
    let usedByNewsletter = [];

    for (let i = 0; i < faker.random.number(3); i++) {
      const sendDate =
        faker.random.number(3) < 2
          ? faker.date.past()
          : faker.random.number(3) === 2
          ? faker.date.future()
          : null;

      usedByNewsletter.push({
        id: faker.random.uuid(),
        title: faker.lorem.word(),
        sentAt: sendDate < new Date() ? sendDate : null,
        sendAt: sendDate > new Date() ? sendDate : null,
        addressBookName: faker.random.number(3) < 2 ? faker.lorem.word() : null
      });
    }

    incentiveVoucher.update({
      usedByNewsletter,
      inUse: usedByNewsletter.length > 0
    });
  }
});
