import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';

import { formatCurrency } from '@additive-apps/ui/utils/format-currency';

export default class IncentiveVoucherModel extends Model {
  @service currentUser;
  @service intl;
  @service uiAppSettings;

  @attr('string') name;
  @attr('string') language;
  @attr('string') greeting;
  @attr('string') termsOfUse;
  @attr('string') previewUrl;
  @attr('string', {
    defaultValue: 'incentive'
  })
  type;

  @attr('number') amount;
  @attr('boolean') inUse;
  @attr usedByNewsletter;

  @attr image;
  @attr availableLanguages;
  @attr publishedLanguages;
  @attr('string') publishedState;
  @belongsTo('incentive-category') category;

  // voucher validity
  @attr('string') validityPeriod;
  @attr('string') validityPeriodCustomDate;

  // voucher is only public for a limited time
  @attr('boolean') timeLimited;
  @attr('string') publicFrom;
  @attr('string') publicUntil;

  get listDescription() {
    let validityPeriodText = this.intl.t('vouchers.expirationFormats.' + this.validityPeriod);
    if (this.validityPeriod === 'custom') {
      validityPeriodText = this.validityPeriodCustomDate;
    }

    let description = `${this.intl.t(
      'incentiveVouchers.listDescription.validity'
    )}: ${validityPeriodText}`;

    if (this.amount) {
      const formattedAmount = formatCurrency({
        value: this.amount,
        currency: this.uiAppSettings?.currency,
        locales: this.currentUser?.user?.language,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        divider: 1
      });

      description += ` – ${this.intl.t(
        'incentiveVouchers.listDescription.amount'
      )}: ${formattedAmount}`;
    }

    return description;
  }
}
