export const IncentiveCategories = (server) => {
  server.post('/incentive-categories', (schema, request) => {
    let body = JSON.parse(request.requestBody);

    return schema.incentiveCategories.create({
      ...body
    });
  });

  server.get('/incentive-categories', (schema) => {
    return schema.incentiveCategories.all();
  });

  server.get('/incentive-categories/:id', (schema, { params: { id } }) => {
    return schema.incentiveCategories.find(id);
  });

  server.put('/incentive-categories/:id', (schema, request) => {
    const { id } = request.params;
    const requestBody = JSON.parse(request.requestBody);

    const incentiveVoucher = schema.incentiveCategories.find(id);

    return incentiveVoucher.update(requestBody);
  });
};
