import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { categoryValidation } from 'additive-voucher/validations/incentive-category';

export default class InstanceVouchersCategoriesCategory extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service intl;
  @service multimedia;
  @service router;
  @service uiPaths;

  validation = null;

  get isViewer() {
    return this.currentUser?.isViewer;
  }

  constructor() {
    super(...arguments);

    this.validation = categoryValidation(this.intl);
  }

  /**
   * Save category
   *
   * @function onSave
   */
  @action
  onSave(record) {
    return record.save();
  }

  /**
   * Close category dialog
   *
   * @function onClose
   */
  @action
  onClose() {
    this.router.transitionTo('instance.incentive-vouchers.categories');
  }
}
