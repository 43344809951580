import Controller from '@ember/controller';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, timeout, all } from 'ember-concurrency';

export default class InstanceIncentiveVouchersCategories extends Controller {
  @service currentUser;
  @service intl;
  @service router;
  @service uiDialog;
  @service uiToast;

  @tracked
  activeCategory = null;

  @tracked
  isDeleteDialog = false;

  get isViewer() {
    return this.currentUser?.isViewer;
  }

  @(task(function* (category) {
    let tasks = [];

    if (category.voucherCount > 0) {
      // show error toast
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    } else {
      try {
        // save category and keep the task running for at least 250ms
        tasks.push(category.destroyRecord());
        tasks.push(timeout(250));
        yield all(tasks);

        this.activeCategory = null;
        this.isDeleteDialog = false;

        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (e) {
        // show error toast
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }
  }).drop())
  deleteCategoryTask;

  @action
  deleteCategory(category) {
    const { intl } = this;
    this.uiDialog.showConfirm(
      intl.t('vouchers.category.deleteCategory.title'),
      intl.t('vouchers.category.deleteCategory.description', {
        name: category?.name,
        htmlSafe: true
      }),
      () => {
        this.deleteCategoryTask.perform(category);
      },
      intl.t('global.actions.delete'),
      true,
      true
    );
  }

  @action
  editCategory(id) {
    this.router.transitionTo('instance.incentive-vouchers.categories.category', id);
  }

  @action
  filterVoucher(category) {
    this.router.transitionTo('instance.incentive-vouchers', {
      queryParams: { category: category }
    });
  }
}
