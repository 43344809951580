import Route from '@ember/routing/route';

import FullWidthMixin from '@additive-apps/ui/mixins/ui-full-width-view';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

import { expirationFormats } from 'additive-voucher/utils/constants';
import { validation } from 'additive-voucher/validations/incentive-voucher';

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import {
  showWarningDialogBeforeClose,
  removeWarningDialogBeforeClose
} from '@additive-apps/ui/utils/close-tab-utils';

export default class InstanceVouchersIndexCreate extends Route.extend(FullWidthMixin) {
  @service currentUser;
  @service intl;
  @service multimedia;
  @service router;
  @service uiAppSettings;

  get currentLocale() {
    return this.uiAppSettings?.currentLocale;
  }

  get defaultLanguage() {
    return this.uiAppSettings?.languages?.defaultLanguage;
  }

  renderTemplate() {
    this.render('instance/incentive-vouchers/index/create', {
      into: 'application'
    });
  }

  beforeModel() {
    super.beforeModel(...arguments);

    if (this.currentUser.isViewer) {
      return this.router.transitionTo('instance.incentive-vouchers.index');
    }
  }

  setupController(controller) {
    super.setupController(...arguments);

    const intl = this.intl;
    const EXPIRATION_FORMATS = expirationFormats(intl);

    const validityPeriod = EXPIRATION_FORMATS[0].value;

    const modelRef = this.store.createRecord('incentive-voucher', {
      validityPeriod,
      language: this.currentLocale
    });

    const validator = validation(intl);
    controller.changeset = new Changeset(modelRef, lookupValidator(validator), validator);

    showWarningDialogBeforeClose(
      this.router,
      this.router.currentRouteName?.slice(0, -6),
      () => controller.getChangesets()?.isDirty,
      () => set(controller, 'isDiscardChangesDialog', true),
      true
    );
  }

  resetController() {
    super.resetController(...arguments);
    removeWarningDialogBeforeClose(this.router);

    this.uiAppSettings.setLocale(this.defaultLanguage);

    this.multimedia.resetSelectionOptions();
  }
}
